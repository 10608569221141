$( document ).ready(function($) {
  ////////////////////////
  // Form Submit unterbinden und clientseitige JS Validierung ausführen
  (function() {
    'use strict';
    window.addEventListener('load', function() {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function(form) {
        form.addEventListener('submit', function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
          // Adds Background to invalid Element-Groups
          $('.was-validated :invalid, .was-validated .is-invalid')
            .parents('.input-group, .tab-content')
            .addClass("bg-invalid-danger")
            .children('.invalid-feedback')
            .show();
        }, false);
      });
    }, false);
  })();
  // Form Submit unterbinden und clientseitige JS Validierung ausführen
  ////////////////////////

  ////////////////////////
  // Validation Fixes

  $('input,label').click(function() {
    // Remove Invalid-Background on Valid inputs in Tab-Content
    $('.was-validated :valid, .was-validated .is-valid')
      .parents('.input-group, .tab-content')
      .removeClass("bg-invalid-danger");
    $('.was-validated :valid, .was-validated .is-valid')
      .parents('.input-group, .tab-content')
      .children('.invalid-feedback')
      .hide();

  });

  // Validation Fixes ENDE
  ////////////////////////

  ////////////////////////
  // Datepicker Initialisieren


  // Initiales Datum als Unix Timestamp an hidden Field übergeben

  var trainingstermin = $('#trainingstermin_calender').datetimepicker.date;

  $('#trainingstermin').prop('value', moment(trainingstermin).format('X'));

  // Gewählte Datum nach Änderung an hidden Field übergeben

  $('#trainingstermin_calender').on('change.datetimepicker', function(e){

    trainingstermin = e.date;
    // console.log(trainingstermin);

    // Gewähltes Datum als Unix Timestamp übergeben
    $('#trainingstermin').prop('value', moment(trainingstermin).format('X'));
  });


  // Datenpicker Tabelle Responsiv-Class anpassen
  $('#trainingstermin_calender')
    .find('table')
    .removeClass('table-sm')
    .addClass('table-xs');

  // Datepicker ENDE
  ////////////////////////

  ////////////////////////
  // Table-Row: Tablelike Divs und Table Row clickable und Validation-Class removal
  $('.card > .card-body > .input-group').click(function() {
      // Debug ausgabe
      // Trainingsauswahl Divs die tabellarisch aussehen
      $(this).find('input[type=radio]').prop('checked', true);
      // Remove Invalid-Background on Valid inputs in Table-Div-Rows
      $('.was-validated :valid, .was-validated .is-valid')
        .parents('.input-group')
        .removeClass("bg-invalid-danger");
  });
  // Table-Row: mit mit toggle: Toggle auslösen wenn TR geklickt wird
  $('.table .tableRowToggle').click(function() {
      var target = $(this).find('[data-toggle="collapse"]').attr('data-target');
      console.log(target);
      $(target).collapse('toggle');
  });
  // Table Row Clickable ENDE
  ////////////////////////

  ////////////////////////
  // Tooltips
  $('.invalid-feedback [data-toggle="tooltip"]').tooltip()
  // Tooltips ENDE
  ////////////////////////

  ////////////////////////
  // Gutschein Wert Toggle Conditional und Validierung setzen

  // Individuellen Wert ausblenden, löschen wenn fester Wert geklickt ist und Validierung entfernen
  $('[for^="gutscheinWert-"]').click(function() {
    $('#wertIndividuell').collapse('hide');
    $('#wertIndividuell input')
      .val("")
      .prop('required',false);
  });

  // Validierung setzen wenn idividueller Wert geklickt ist
  $('[for^="gutscheinWert-individuell"]').click(function() {
    $('#gutscheinWertIndividuell').prop('required',true);
  });

  // Required togglen für Wert- und Trainingsgutschein-Felder
  $('#gutscheinart-wertgutschein-tab').on('show.bs.tab', function () {
    $('#gutscheinart-wertgutschein input').prop('required', true);
    $('#gutscheinart-trainingsgutschein input, #gutscheinart-trainingsgutschein select, #gutscheinart-trainingsgutschein select option')
      .prop('required', false)
      .prop('checked', false)
      .prop('selected', false)
      .parents('label')
      .removeClass('active');
  });

  $('#gutscheinart-trainingsgutschein-tab').on('show.bs.tab', function () {
    $('#gutscheinart-wertgutschein input')
      .prop('required', false)
      .prop('checked', false)
      .parents('label')
      .removeClass('active');
    $('#gutscheinart-trainingsgutschein input, #gutscheinart-trainingsgutschein select')
      .prop('required', true);
  });

  // Gutschein Wert Toggle Conditional ENDE
  ////////////////////////

  ////////////////////////
  // Required togglen für optionale Lieferadresse
  $('#collapseLieferadresse').on('show.bs.collapse', function () {
    $('#LIansprache-frau, #LIansprache-herr, #LIinputVorname, #LIinputNachname, #LIinputStrasse, #LIinputHausnr, #LIinputPlz, #LIinputOrt, #LIselectLand, #LIinputTelefonnummer')
      .prop('required', true);
  });

  $('#collapseLieferadresse').on('hide.bs.collapse', function () {
    $('#LIansprache-frau, #LIansprache-herr, #LIinputVorname, #LIinputNachname, #LIinputStrasse, #LIinputHausnr, #LIinputPlz, #LIinputOrt, #LIselectLand, #LIinputTelefonnummer')
      .prop('required', false)
      .prop('checked', false)
      .prop('value', "");
  });

  $('#collapseLieferadresse').on('hide.bs.collapse', function () {
    $('#LIinputTitel, #LIinputFirma')
      .prop('required', false)
      .prop('checked', false)
      .prop('value', "");
  });

  // Required togglen für optionale Lieferadresse ENDE
  ////////////////////////
});
